import './App.css';
import picture from "./assets/construction.png"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>We are building for you!</h2>
        <img src={picture}/>
        <h3>be patient :)</h3>
      </header>
    </div>
  );
}

export default App;
